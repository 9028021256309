<template>
    <div class="news">
        <div class="block-content mb-11">
            {{ $t('news.landing.content[0]') }}
        </div>
        <Anchor :id="'now'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('news.landing.title[0]') }}
            </div>
            <ThreeBlock
                :block-data="now"
                :router-name="'NewsNowItem'"
                :more-router-link="'NewsNow'"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'social-innovation'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('news.landing.title[1]') }}
            </div>
            <div class="block-content">
                {{ $t('news.landing.content[1]') }}
            </div>
            <ThreeBlock
                :block-data="socialInnovation"
                :router-name="'NewsSocialItem'"
                :more-router-link="'NewsSocial'"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'teams'" />
        <div class="sub-block mb-16">
            <div class="block-title">
                <div class="dot" />
                {{ $t('news.landing.title[2]') }}
            </div>
            <div class="block-content">
                {{ $t('news.landing.content[2]') }}
            </div>
            <ThreeBlock
                :block-data="teams"
                :router-name="'NewsTeamsItem'"
                :more-router-link="'NewsTeams'"
                :show-data="showData"
            />
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import ThreeBlock from '@/components/list/ThreeBlock.vue';
import listTextRouterLabel from '@/static-data/listTextRouterLabel.json';

export default {
    components: {
        ThreeBlock,
    },
    data() {
        return {
            showData: false,
            now: {
                type: 'router',
                items: null,
            },
            socialInnovation: {
                type: 'router',
                items: listTextRouterLabel,
            },
            teams: {
                type: 'router',
                items: listTextRouterLabel,
            },
        };
    },
    watch: {
        $route() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                const res = await API.Article.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.now.items = res.data.raw.filter(
                    (value) => value.category === '最新資訊',
                );
                this.socialInnovation.items = res.data.raw.filter(
                    (value) => value.category === '社創專欄',
                );
                this.teams.items = res.data.raw.filter(
                    (value) => value.category === '團隊專欄',
                );
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            this.showData = true;
        },
    },
};
</script>

<style lang="scss">
.news{
    color: $color-gray;

    .block-content{
        font-size: 16px;
        font-weight: 700;
        line-height: 2;
        text-align: justify;
        letter-spacing: 0.1em;
    }
}
</style>
